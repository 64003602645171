@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';

.wrapper {
  position: relative;
  background: $brand-white;
  box-shadow: $shallow-drop-shadow;
}

.flexContainer {
  display: flex;
}

.logoContainer {
  width: 28%;
  background-color: $brand-white;

  @include breakpoint(1200px, min) {
    width: 20%;
  }

  @include breakpoint(1700px, min) {
    width: 30%;
  }

  @include breakpoint(2100px, min) {
    width: 40%;
  }

  @include breakpoint(2600px, min) {
    width: 45%;
  }

  @include breakpoint(3000px, min) {
    width: 60%;
  }

  @include breakpoint(5000px, min) {
    width: 70%;
  }
}

.navContainer {
  width: 100%;
  height: 113px;

  @include breakpoint($custom-nav-items-breakpoint, min) {
    height: 103px;
  }
}

.topContainer {
  background: linear-gradient(
    90deg,
    $brand-white 0%,
    $brand-primary-tints-100 5.33%
  );
  height: 32px;
  @include breakpoint($custom-nav-items-breakpoint, max) {
    height: 42px;
  }
}

.topContainerWhite {
  background: $brand-white;
  height: 32px;
  @include breakpoint($custom-nav-items-breakpoint, max) {
    height: 42px;
  }
}

.logoWrapper {
  background: $brand-white;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 15px;
  min-width: 288px;
}

.nexplanonLogo {
  line-height: 0;
  max-width: 273px;
  padding-top: 8px;
}

.wrapperContainer {
  width: 100%;
  background-color: transparent;
  position: absolute;
  z-index: 15;
  top: 0px;

  [class*='_container_'] {
    @include breakpoint(xl, max) {
      max-width: 100%;
    }
  }

  a,
  button {
    cursor: pointer;
  }
}

.mainNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 113px;

  @include breakpoint($custom-nav-items-breakpoint, min) {
    height: 103px;
  }
}

.mainNavContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;

  .loginButton {
    padding-top: 2px;
  }
}

.topNavigation {
  height: 32px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(
    90deg,
    $brand-white 0%,
    $brand-primary-tints-100 5.33%
  );

  a,
  span:not(.textBlock),
  button.link {
    min-width: max-content;
    display: flex;
    align-items: center;
    @include main-top-navigation;
    margin-left: 16px;
    color: $brand-secondary-3-tints-500;
    text-decoration: none;

    &:hover {
      color: $text-color-utulity-hover;
    }

    @include breakpoint($custom-nav-items-breakpoint, max) {
      &:first-child {
        max-width: 130px;
      }
      min-width: min-content;
      max-width: 100px;
      line-height: 1.2;
      text-align: center;
    }
  }

  &:nth-child(1) {
    span:nth-child(1) {
      @include breakpoint(xl, max) {
        min-width: min-content;
        max-width: 130px;
        text-align: center;
      }
    }
  }

  button.link {
    cursor: pointer;
    padding: 0;
    border: 0;
    background: transparent;
    -webkit-appearance: none;
    appearance: none;
  }

  span {
    cursor: pointer;
  }

  svg {
    margin-left: 2px;
    transform: scale(0.8);
  }

  @include breakpoint($custom-nav-items-breakpoint, max) {
    height: 42px;
  }
}

.mainMenu {
  height: 71px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0px;
  padding-inline-start: 0px;
  ul + button {
    margin-left: 32px;
  }

  .ctaContainer {
    display: flex;
  }

  .ctaButton {
    min-width: 163px;
    margin-left: 24px;

    @include breakpoint(xl, min) {
      &:first-child {
        margin-left: 30px;
      }

      &:nth-child(2) {
        margin-left: 10px;
      }
    }
  }
}

.alt {
  .topNavigation {
    background: $brand-primary-tints-100;
  }

  .ctaButton {
    margin-left: 24px;

    @include breakpoint(xl, min) {
      &:nth-child(2) {
        margin-left: 24px;
      }
    }
  }

  .mainMenu {
    @include breakpoint(xl, min) {
      height: 61px;
    }
  }

  .topContainer {
    height: 42px;
  }

  .logoWrapper {
    @include breakpoint(xl, min) {
      max-width: 279px;
    }

    @include breakpoint(xxl, min) {
      max-width: 288px;
    }
  }

  .topNavigation {
    height: 42px;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;

    button.link,
    a {
      line-height: 1;
      display: inline-block;
      text-align: left;
      max-width: 170px;

      @include breakpoint(1080px, max) {
        font-size: 0.8125rem;
      }

      @include breakpoint(xl, min) {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .textBlock {
        display: inline;

        @include breakpoint(xl, min) {
          display: block;
        }
      }
    }
  }
}

.singleNavigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 33px;
  height: 113px;

  @include breakpoint($custom-nav-items-breakpoint, min) {
    height: 103px;
  }

  a {
    @include main-navigation;
    text-decoration: none;
    font-weight: $font-weight-bold;

    &:hover {
      text-decoration: underline;
    }
  }
}
