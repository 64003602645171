@import 'styles/typography.scss';
@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';
@import 'mixin.scss';

.button {
  @include resetButtonStyles;
  @include button;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  border-radius: 80px;
  border: 2px solid transparent;
  color: $text-color-primary;

  // Branding
  &.primary {
    @include primary;
  }

  &.secondary {
    @include secondary;
  }

  &.secondary2 {
    @include secondary2;
  }

  &.secondary4 {
    @include secondary4;
  }

  &.outlineSecondary {
    @include outlineSecondary;
  }

  &.outlineSecondary2 {
    @include outlineSecondary2;
  }

  &.primaryLight {
    @include primaryLight;
  }

  &.primaryLight2 {
    @include primaryLight2;
  }

  &.secondaryLight {
    @include secondaryLight;
  }

  &[disabled] {
    opacity: 0.8;
    pointer-events: none;
  }

  // Sizes
  &.large {
    padding: 0 24px;
    min-height: 51px;
  }

  &.medium {
    padding: 3px 16px 0 16px;
    min-height: 39px;
    width: max-content;
  }

  &.small {
    padding: 0 16px;
    min-height: 35px;
  }

  // Misc
  &.simple {
    border-radius: 0;
    padding: 0;
    height: auto;
    color: $brand-secondary;

    &:hover {
      color: $text-color-link-hover;

      svg {
        color: $text-color-link-hover;
      }
    }
  }
}
